.videowrapper {
    /*float: none;*/
    /*clear: both;*/
    width: 100%;
    /*position: relative;*/
    /*padding-bottom: 56.25%;*/
    /*padding-top: 25px;*/
    height: 100%;
}
.videowrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}